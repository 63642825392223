const routes = [
  {
    path: "",
    redirect: window.innerWidth < 767 ? "/mobileindex" : "/faceswap",
    // redirect: "/mobileindex",
    // redirect: "/faceswap",
  },
  {
    name: "test",
    path: "/test",
    component: () => import("@/pages/test.vue"),
    meta: {
      title: "test",
      requireAuth: true,
    },
  },
  {
    name: "mobileindex",
    path: "/mobileindex",
    component: () => import("@/mobilepages/index/index.vue"),
    meta: {
      title: "mobileindex",
      requireAuth: true,
    },
  },
  {
    name: "mobiletest",
    path: "/mobiletest",
    component: () => import("@/mobilepages/test.vue"),
    meta: {
      title: "mobiletest",
      requireAuth: true,
    },
  },
  {
    name: "faceswap",
    path: "/faceswap",
    component: () => import("@/pages/faceswap/faceswap.vue"),
    meta: {
      title: "faceswap",
      requireAuth: true,
    },
  },
  {
    name: "paydialog",
    path: "/paydialog",
    component: () => import("@/pages/paydialog/paydialog.vue"),
    meta: {
      title: "paydialog",
      requireAuth: true,
    },
  },
  {
    name: "logindialog",
    path: "/logindialog",
    component: () => import("@/pages/logindialog/logindialog.vue"),
    meta: {
      title: "logindialog",
      requireAuth: true,
    },
  },
];
export default routes;
